import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { MaterialBomDetailList } from '@/resource/model';
import OsTable, { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { messageError, showWarningConfirm, translation } from '@/utils';
import { cloneDeep, uniqueId } from 'lodash-es';
import { Message } from 'element-ui';
import { SelectMaterial } from '@/views/dialogs';
import { billOfMaterialService } from '@/api';

@Component({
  name: 'BomList',
  components: { SelectMaterial }
})
export default class BomList extends Vue {
  @Prop({
    type: Array,
    required: false,
    default: () => {
      return [];
    }
  })
  public materialBomDetailList!: Array<MaterialBomDetailList> | null;
  @Prop({
    type: Object,
    required: false,
    default: () => {
      return null;
    }
  })
  public rowData!: MaterialBomDetailList;
  public tableOptions: OsTableOption<Partial<MaterialBomDetailList>> = {
    loading: false,
    data: [],
    rowKey: () => {
      return 'uuid';
    },
    closeAdaptiveHeight: true
  };

  public columnOptions: Array<OsTableColumn<MaterialBomDetailList>> = [
    {
      type: 'selection',
      prop: 'uuid',
      label: '',
      fixed: true,
      width: '58px'
    },
    {
      type: 'index',
      prop: 'id',
      fixed: true,
      label: 'common.index',
      width: '80px'
    },
    {
      prop: 'materialCode',
      label: 'materialBom.materialCode',
      minWidth: '200px'
    },
    {
      prop: 'materialName',
      label: 'materialBom.materialName',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'specification',
      label: 'materialBom.specification',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'materialUnitName',
      label: 'materialList.baseUnit',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'type',
      label: 'materialBom.type',
      minWidth: '180px'
    },
    {
      prop: 'typeParam',
      label: 'materialBom.typeParam',
      minWidth: '160px'
    },
    {
      prop: 'numerator',
      label: 'materialBom.numerator',
      minWidth: '160px'
    },
    {
      prop: 'denominator',
      label: 'materialBom.denominator',
      minWidth: '160px'
    },
    {
      prop: 'fixedAttrition',
      label: 'materialBom.fixedAttrition',
      minWidth: '160px'
    },
    {
      prop: 'remark',
      label: 'common.remark',
      minWidth: '160px'
    }
    // {
    //   prop: 'taxAmount',
    //   label: 'billing.taxAmount',
    //   minWidth: '160px',
    //   formatter: (row: object): number => {
    //     return decimalToNumber2(new Decimal((row as MaterialBomDetailList).taxAmount));
    //   }
    // },
  ];

  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: '',
      handleClick: this.addDataRow
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.batchDelete',
      operationType: 'batchDelete',
      icon: 'el-icon-delete',
      permissionCode: 'finance:bill:deleteInvoiceDetail',
      plain: true,
      disabled: true,
      handleClick: this.batchDelete
    }
  ];

  public selectedRows: Array<MaterialBomDetailList> = [];
  public editRow: MaterialBomDetailList | null = null;
  public deleteIds: Array<number | undefined> = [];

  public selectMaterialDialog: boolean = false;

  public typeOptions: Array<{ label: string; value: string }> = [];
  public usageCalculationParametersOptions: Array<{ label: string; value: string }> = [];

  private readonly canEditCells: Array<Partial<keyof MaterialBomDetailList>> = [
    'materialCode',
    'type',
    'typeParam',
    'numerator',
    'denominator',
    'fixedAttrition',
    'remark'
  ];
  private readonly defaultBomItemProps: Partial<MaterialBomDetailList> = {
    id: null,
    uuid: '',
    lineNum: null,
    /**
     * 物料编号
     */
    materialCode: '',
    /**
     * 物料名称
     */
    materialName: '',
    /**
     * 物料规格型号
     */
    specification: '',
    /**
     * 物料单位
     */
    materialUnitName: '',
    /**
     * 用量类型
     */
    type: 'CHANGE',
    /**
     * 用量计算参数
     */
    typeParam: 'MADE_QUANTITY',
    /**
     * 分子
     */
    numerator: 1.0,
    /**
     * 分母
     */
    denominator: 1.0,
    /**
     * 固定耗损
     */
    fixedAttrition: 0.0,
    /**
     * 描述
     */
    remark: '',

    /**
     * 属性
     */
    attribute: '',

    /**
     * 领取方式
     */
    receiveMethod: 'ORDINARY',
    /**
     * 默认发料仓库
     */
    receiveWarehouseId: null
  };

  public created(): void {
    this.getTypeList();
    this.getUsageCalculationParametersList();
  }
  public getTypeList(): void {
    try {
      this.typeOptions = billOfMaterialService.getUsageTypeList();
    } catch (error) {
      messageError(error);
    }
  }
  public getUsageCalculationParametersList(): void {
    try {
      this.usageCalculationParametersOptions = billOfMaterialService.getUsageCalculationParametersList();
    } catch (error) {
      messageError(error);
    }
  }
  public handleBomSelectionChange(selectedData: Array<MaterialBomDetailList>): void {
    this.selectedRows = selectedData;
  }
  public handleRowClick(row: MaterialBomDetailList): void {
    this.$emit('setRowData', row);
  }
  /**
   * 处理可编辑单元格的点击事件
   * @param prop 处理
   * @param rowData 当前行的数据
   */
  public handlePropClick(prop: string, rowData: { [P: string]: any }): void {
    rowData[`${prop}Input`] = true;
  }

  public handleBlur(rowData: MaterialBomDetailList, inputName: string): void {
    switch (inputName) {
      case 'numeratorInput':
        if (!rowData.numerator) rowData.numerator = 1;
        break;
      case 'denominatorInput':
        if (!rowData.denominator) rowData.denominator = 1;
        break;
      case 'fixedAttritionInput':
        if (!rowData.fixedAttrition) rowData.fixedAttrition = 0;
        break;
      default:
        break;
    }
    setTimeout(() => {
      Vue.set(rowData, `${inputName}`, false);
    }, 150);
  }

  public openMaterialDialogVisible(rowData: any): void {
    console.log('选择的物料', rowData);
    this.editRow = rowData;
    this.selectMaterialDialog = true;
  }
  public handleSelectedMaterial(rowData: any): void {
    console.log('选中的值', rowData);
    const parentMaterialCode = this.$parent.$parent.$parent.$data.form.materialCode;
    if (rowData.code === parentMaterialCode) {
      Message.error('请选择不同于父物料不同的物料');
    } else {
      this.tableOptions.data.forEach(x => {
        if (x.uuid === this.editRow!.uuid) {
          Vue.set(x, `materialId`, rowData.id);
          Vue.set(x, `materialCode`, rowData.code);
          Vue.set(x, `materialName`, rowData.name);
          Vue.set(x, `materialUnit`, rowData.baseUnit);
          Vue.set(x, `materialUnitName`, rowData.baseUnitName);
          Vue.set(x, `specification`, rowData.specification);
          Vue.set(x, `attribute`, rowData.attribute);
          Vue.set(x, `receiveWarehouseId`, rowData.inventory.warehouseId);
          Vue.set(x, `receiveWarehouseName`, rowData.inventory.warehouseName);
        }
      });
      this.editRow = null;
    }
  }

  private addDataRow(): void {
    const dataRow = cloneDeep(this.defaultBomItemProps);
    dataRow.uuid = this.createUuid();
    this.initInputDynamicProp(dataRow);
    this.tableOptions.data.push(dataRow);
  }

  private copyInvoice(): void {
    // const copyRows = cloneDeep(this.selectedRows);
    // copyRows.forEach(x => {
    //   x.uuid = this.createUuid();
    //   delete x.id;
    //   this.initInputDynamicProp(x);
    // });
    // this.tableOptions.data.push(...copyRows);
    // this.clearSelection();
  }

  private clearSelection(): void {
    (this.$refs.bomDetailsTable as OsTable).clearSelection();
    this.selectedRows = [];
  }

  private batchDelete(): void {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          // 对于尚未保存的明细，使用uuids进行删除
          const uuids = this.selectedRows.filter(x => !x.id).map(x => x.uuid);
          if (uuids.length > 0) {
            const filterData = this.tableOptions.data.filter(x => !uuids.includes(x.uuid!));
            // 对于prop.sync的数据只能使用vue包装过的数组处理方法，先清除再添加
            this.tableOptions.data.splice(0, this.tableOptions.data.length);
            this.tableOptions.data.push(...filterData);
          }

          const ids = this.selectedRows.map(x => x.id!).filter(x => x);
          if (ids.length > 0) {
            this.deleteIds = [...this.deleteIds, ...ids].filter(x => x);
            const filterData = this.tableOptions.data.filter(x => !ids.includes(x.id!));
            // 对于prop.sync的数据只能使用vue包装过的数组处理方法，先清除再添加
            this.tableOptions.data.splice(0, this.tableOptions.data.length);
            this.tableOptions.data.push(...filterData);
          }

          Message.success(translation('operationRes.deleteSuccess'));
          this.$emit('shop-deleted', this.selectedRows);
          this.clearSelection();
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private createUuid(): string {
    return uniqueId('bom-details');
  }
  private resetTableDataUUID(): void {
    const uuidList = this.tableOptions.data.map(x => x.uuid);
    if (!this.rowData || !uuidList.includes(this.rowData!.uuid!)) {
      this.$emit('setRowData', null);
    }
    this.tableOptions.data.forEach((x, index) => {
      x.uuid = this.createUuid();
      x.lineNum = index + 1;
    });
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<MaterialBomDetailList>): void {
    this.operationOptions.forEach(x => {
      if (x.operationType !== 'add') x.disabled = value.length === 0;
    });
  }
  @Watch('tableOptions.data', { immediate: true })
  private watchTableData(): void {
    this.resetTableDataUUID();
  }
  @Watch('materialBomDetailList', { immediate: true })
  private initData(): void {
    this.tableOptions.data = this.materialBomDetailList || [];
    this.tableOptions.data.forEach(x => {
      this.initInputDynamicProp(x);
    });
  }

  /**
   * 初始化项次内，部分可以动态使用input编辑的属性
   * @param rowObj行对象
   * @param canEditCells 要初始化的列
   */
  private initInputDynamicProp(rowObj: any): void {
    this.canEditCells.forEach(x => {
      Vue.set(rowObj, `${x}Input`, false);
    });
  }
}
