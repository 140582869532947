import { billOfMaterialService } from '@/api/';
import { TagsViewModule } from '@/store/modules/tags-view';
import { messageError, translation } from '@/utils';
import { Component, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router/types/router';
import { SelectMaterial, SelectWarehouse } from '@/views/dialogs';

import { Form, Message, Notification } from 'element-ui';
import { BillOfMaterialSource, MaterialBomDetailList } from '@/resource/model';
import BomList from './bom-list/bom-list.vue';
Component.registerHooks(['beforeRouteLeave']);
@Component({
  name: 'AddMaterial',
  components: {
    SelectMaterial,
    SelectWarehouse,
    BomList
  }
})
export default class AddBom extends Vue {
  /**
   *  物料ID
   */
  public id: number | null = null;
  public warehouseId: number = 0;

  public type = this.$route.query.type;

  public submitLoading: boolean = false;
  public loading: boolean = false;
  public materialDialogVisible: boolean = false;
  public warehousDialogVisible: boolean = false;
  public valueType: string = '';

  public rowData: MaterialBomDetailList | null = {} as MaterialBomDetailList;

  public paymentOptions: Array<{ label: string; value: string }> = [];

  public form: BillOfMaterialSource = {
    bomVersion: '',
    bomName: '',
    materialId: undefined,
    materialCode: '',
    materialName: '',
    materialUnit: undefined,
    materialUnitName: '',
    specification: '',
    attribute: '',
    remark: '',
    detailList: []
  };
  public formRules = {
    bomVersion: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('common.input') + translation('materialBom.bomVersion')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          billOfMaterialService
            .checkCode(value, this.form?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('materialBom.bomVersion') + translation('common.repeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    bomName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('common.input') + translation('materialBom.bomName')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
      // {
      //   validator: (rule: any, value: string, callback: Function): void => {
      //     billOfMaterialService
      //       .checkName(value, this.form?.id)
      //       .then((isRepeat: boolean) => {
      //         if (isRepeat) {
      //           callback(translation('materialBom.bomName') + translation('common.repeat'));
      //           return;
      //         }
      //         callback();
      //       })
      //       .catch(error => {
      //         callback(error);
      //       });
      //   },
      //   trigger: 'blur'
      // }
    ],
    materialCode: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('common.select') + translation('materialBom.mainMaterialCode')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    inventoryTypeId: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('common.select') + translation('materialList.inventoryType')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    inventoryUnit: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('common.select') + translation('materialList.inventoryUnit')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ]
  };

  private get attribute(): string {
    console.log(translation(`materialPropertyEnum.${this.form.attribute}`));
    return this.form.attribute ? translation(`materialPropertyEnum.${this.form.attribute}`) : '';
  }

  public setRowData(row: MaterialBomDetailList): void {
    this.rowData = row;
  }
  public openSelectMaterialDialog(value: string): void {
    this.materialDialogVisible = true;
    this.valueType = value;
  }
  public handleSelectedMaterial(row: any): void {
    console.log('选择的物料', row);
    switch (this.valueType) {
      case 'materialCode':
        this.form.materialId = row.id;
        this.form.materialCode = row.code;
        this.form.materialName = row.name;
        this.form.materialUnit = row.baseUnit;
        this.form.materialUnitName = row.baseUnitName;
        this.form.specification = row.specification;
        this.form.attribute = row.attribute;
        break;
    }
    (this.$refs.bomListRef as BomList).tableOptions.data = [];
  }

  public openSelectWarehousDialog(value: string): void {
    this.warehousDialogVisible = true;
    this.valueType = value;
  }
  public handleSelectWarehous(row: any): void {
    switch (this.valueType) {
      case 'receiveWarehouseId':
        this.rowData!.receiveWarehouseId = row.id;
        this.rowData!.receiveWarehouseName = row.name;
        break;
    }
  }

  public onSubmit(): void {
    this.setLoading(true);
    (this.$refs.formRefs as Form).validate(async (valid: boolean) => {
      let errorMsg = '';
      this.form.detailList = (this.$refs.bomListRef as BomList).tableOptions.data as Array<MaterialBomDetailList>;
      if (this.form.detailList.length === 0) {
        errorMsg += `<br />未填写物料清单！<br />`;
      }

      this.form.detailList.forEach((x, index) => {
        if (!x.materialCode) {
          errorMsg += `<br />序号为${index + 1}的 “物料编码” 不能为空！<br />`;
        }
        if (!x.type) {
          errorMsg += `序号为${index + 1}的 “用量类型” 不能为空！<br />`;
        }
        if (!x.typeParam) {
          errorMsg += `序号为${index + 1}的 “用量计算参数” 不能为空！<br />`;
        }
        if (x.numerator < 1) {
          errorMsg += `序号为${index + 1}的 “用量（分子）” 不能为空！<br />`;
        }
        if (x.denominator < 1) {
          errorMsg += `序号为${index + 1}的 “用量（分母）” 不能为空！<br />`;
        }
        if (errorMsg) {
          return;
        }
      });

      if (errorMsg) {
        Notification.error({
          title: translation('dialog.saveFailed'),
          duration: 0,
          dangerouslyUseHTMLString: true,
          message: `<span>${errorMsg}</span><br />`
        });
        return;
      }
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        if (this.type === 'add') {
          await billOfMaterialService.post({ ...this.form } as any);
          Message.success(translation('operationRes.operationSuccess'));
          this.$router.go(-1);
          return;
        }
        await billOfMaterialService.put({ ...this.form } as any);
        Message.success(translation('operationRes.operationSuccess'));
        this.$router.go(-1);
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }

  public setLoading(value: boolean): void {
    this.loading = value;
  }

  public activated(): void {}

  public created(): void {
    this.getReceiveModeList();
    this.type = this.$route.query.type;
    if (this.type === 'add') {
      // this.getPriceUnits();
    } else {
      this.id = Number(this.$route.query.id);
      this.getDetails(this.id!);
    }
  }

  public getReceiveModeList(): void {
    try {
      this.paymentOptions = billOfMaterialService.getReceiveModeList();
    } catch (error) {
      messageError(error);
    }
  }

  public beforeRouteLeave(to: Route, from: Route, next: Function): void {
    TagsViewModule.DelView(this.$route);
    next();
  }
  private getDetails(id: number): void {
    billOfMaterialService
      .getById(id)
      .then(res => {
        Object.assign(this.form, res);
        // (this.$refs.bomListRef as BomList).tableOptions.data.push(...res.detailList);
      })
      .catch(error => {
        messageError(error);
      });
  }
}
